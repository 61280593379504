import { AfterViewChecked, AfterViewInit, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CLIENTPORTAL, COMMUNITY, MENTORDATABASE, NETWORKING, PROFILE, RESOURCES, TUTORIAL } from '../app-routing.module';

@Component({
  selector: 'fp-iframe-renderer',
  templateUrl: './iframe-renderer.component.html',
  styleUrls: ['./iframe-renderer.component.scss'],
})
export class IframeRendererComponent implements OnInit, AfterViewChecked, OnDestroy {
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) {}
  user_role: string;
  iframeSrc: SafeResourceUrl;
  routeSegment: string;
  listOfMenuItems: string[];

  ngOnInit() {
    this.user_role = localStorage.getItem('user_role');
    this.route.params.subscribe(params => {
      this.routeSegment = params['iframeSrc'];
      let url: string;
      // Check if the route segment exists in the iframeUrlMap
      if (this.iframeUrlMap.hasOwnProperty(this.routeSegment)) {
        const routeData = this.iframeUrlMap[this.routeSegment];
        // Check user role and assign corresponding URL
        switch (this.user_role) {
          case 'ME':
            this.listOfMenuItems = [COMMUNITY, RESOURCES, NETWORKING];
            url = routeData['ME'] || routeData['default'] || this.DEFAULT_URL;
            break;
          case 'MR':
            this.listOfMenuItems = [COMMUNITY, RESOURCES, PROFILE, MENTORDATABASE];

            url = routeData['MR'] || routeData['default'] || this.DEFAULT_URL;
            break;
          case 'CC':
            url = routeData['CC'] || routeData['default'] || this.DEFAULT_URL;
            break;
          case 'CH':
            url = routeData['CH'] || routeData['default'] || this.DEFAULT_URL;
            break;
          default: // Use default URL for unknown user roles
            url = routeData['default'] || this.DEFAULT_URL;
            break;
        }
      } else {
        // Handle case where route segment doesn't exist in iframeUrlMap
        url = this.DEFAULT_URL;
      }
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  ngAfterViewChecked() {
    const resources_element = document.querySelector('a.p-panelmenu-header-action[href="/resources"]');
    if (!this.listOfMenuItems?.includes(this.routeSegment)) {
      if (resources_element) {
        // Remove 'orange-light-background' and 'font-bold' from elements with the 'orange-light-background' class
        this.removeClasses('.p-panelmenu-header.orange-light-background', ['orange-light-background', 'font-bold']);
        // Remove 'p-highlited' and 'font-bold' from elements with the 'p-highlited' class
        this.removeClasses('.p-panelmenu-header.p-highlight', ['p-highlight', 'font-bold']);
        // add highlight to resources menu item
        resources_element.classList.add('orange-light-background', 'font-bold');
      }
    } else {
      this.removeClasses('a.p-panelmenu-header-action[href="/resources"]', ['orange-light-background', 'font-bold']);
    }
  }

  ngOnDestroy(): void {
    this.removeClasses('a.p-panelmenu-header-action[href="/resources"]', ['orange-light-background', 'font-bold']);
  }

  // Function to remove specified classes from elements matching a selector
  removeClasses(selector, classes) {
    document.querySelectorAll(selector).forEach(element => {
      element.classList.remove(...classes);
    });
  }

  iframeUrlMap = {
    'all-resources': {
      default: 'https://v2-embednotion.com/7f25625934d94c6e968ad3186698322f',
      MR: 'https://v2-embednotion.com/7f25625934d94c6e968ad3186698322f',
    },
    b2b: { MR: 'https://v2-embednotion.com/45807c5414ac487e8ed0ff1dddafb7c5' },
    books: {
      ME: 'https://v2-embednotion.com/3861e5adadd24e058ffaa4301430e224',
      MR: 'https://v2-embednotion.com/3b0c6c76ebea45dca337c22e955d99b7',
    },
    'case-studies': {
      MR: 'https://v2-embednotion.com/79df79156a824e338d92a1d9eaf75649',
    },
    challenges: {
      MR: 'https://v2-embednotion.com/3cf054a7533847ebb023519b6d6236e1',
    },
    chemistry: {
      ME: 'https://v2-embednotion.com/ad31334b12954a60bb0045668fa2f837',
      MR: 'https://v2-embednotion.com/7b2328b5fcc94701b98d940c8901882e',
    },
    [CLIENTPORTAL]: {
      default: 'https://v2-embednotion.com/3de09217a60b4f78961e738c4d73176e',
    },
    communicating: {
      ME: 'https://v2-embednotion.com/fc3b3cd10ea742e2b0b44a4895592551',
      MR: 'https://v2-embednotion.com/517b8cbafbaf44779463f8e3a43470e9',
    },
    [COMMUNITY]: {
      default: 'https://v2-embednotion.com/aa16942bd19c4d8db5421ea01cee5804',
      ME: 'https://v2-embednotion.com/aa16942bd19c4d8db5421ea01cee5804',
      MR: 'https://v2-embednotion.com/bf8231a1df0c4ae194dbf7530224540b',
    },
    discounts: {
      ME: 'https://v2-embednotion.com/d52e2606d9ef4bf1b2dc24bb6953b1c3',
      MR: 'https://v2-embednotion.com/9b49d96715724004a1ae443202aeddf1',
    },
    empowering: {
      ME: 'https://v2-embednotion.com/10def82afea64f2aa2ecf1c94b3fc9f9',
    },
    [NETWORKING]: {
      default: 'https://v2-embednotion.com/8b7c1c268aef4d6bbe055f40c568e791',
    },
    'program-overview': {
      default: 'https://v2-embednotion.com/3953a9b5a49442c89d6694f52ff93745',
    },
    'question-guide': {
      ME: 'https://v2-embednotion.com/84834db8357c4b2fbecdc0710bc60cb2',
      MR: 'https://v2-embednotion.com/88359fdf91c545ed8506a29968deb73b',
    },
    referral: {
      ME: 'https://v2-embednotion.com/d50022f6c7e74767884b9eea2eee8978',
    },
    [RESOURCES]: {
      default: 'https://v2-embednotion.com/ed4f9e9a8f8040d2ae8d09c92f8d58bd',
      ME: 'https://v2-embednotion.com/ed4f9e9a8f8040d2ae8d09c92f8d58bd',
      MR: 'https://v2-embednotion.com/907b5af49f6e4d8ab58615061df743ef',
      CH: 'https://v2-embednotion.com/7fad9d41fab646bfa8f8eae056ff1eeb',
    },
    'smartgoals-tips': {
      default: 'https://v2-embednotion.com/e904e1acba3f481c91dbc6e5853553eb',
      ME: 'https://v2-embednotion.com/e904e1acba3f481c91dbc6e5853553eb',
      MR: 'https://v2-embednotion.com/cdab0a197d514f6aa3aab2d336eee9dc',
    },
    tips: {
      MR: 'https://v2-embednotion.com/ffcaf7f6f8e74c41bb17f185ccc26a8b',
    },
    training: {
      default: 'https://v2-embednotion.com/897c57724e224a6ba5e972daa3e95d6d',
      ME: 'https://v2-embednotion.com/897c57724e224a6ba5e972daa3e95d6d',
      MR: 'https://v2-embednotion.com/b350ca011d3640b6a33572bdc0b7c14b',
    },
    trust: {
      ME: 'https://v2-embednotion.com/50fd749593a24ed4b0454bb036387c3e',
    },
    [TUTORIAL]: {
      default: 'https://v2-embednotion.com/f7fafe684bcb4afc9bbb4d7470539f87',
      ME: 'https://v2-embednotion.com/f7fafe684bcb4afc9bbb4d7470539f87',
      MR: 'https://v2-embednotion.com/b350ca011d3640b6a33572bdc0b7c14b',
      CH: 'https://v2-embednotion.com/15f0ca87d43d463ab54846ac6e95c137',
    },
    webinars: {
      ME: 'https://v2-embednotion.com/aff3441cf9834f6d9da10f6cbb7426a9',
      MR: 'https://v2-embednotion.com/a91ad837ca194c55b4f33b51315a4771',
    },
  };

  DEFAULT_URL = 'https://v2-embednotion.com/ed4f9e9a8f8040d2ae8d09c92f8d58bd';
}
