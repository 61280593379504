import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderByDate',
})
export class OrderByDatePipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!Array.isArray(array) || !field) {
      return array;
    }

    return array.sort((a, b) => {
      const timeA = new Date(a[field]).getTime();
      const timeB = new Date(b[field]).getTime();
      return timeB - timeA; // Sort in descending order
    });
  }
}
