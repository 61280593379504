import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { FarewellComponent } from './service-pages/farewell/farewell.component';
import { MaintenanceComponent } from './service-pages/maintenance/maintenance.component';
import { PageNotFoundComponent } from './service-pages/page-not-found/page-not-found.component';
import { CanDeactivateGuard } from './shared/services/can_deactive.service';
import {
  AuthGuardService,
  CompanySectionGuard,
  MentorOrCoachSectionGuard,
  MentorSectionGuard,
  RedirectGuard,
} from './shared/services/auth-guard.service';
import { IframeRendererComponent } from './iframe-renderer/iframe-renderer.component';

export const LOGINURL = 'auth';
export const DL_SIGNUP_URL = 'auth/dlsignup';
export const PASSWORD_RESET_URL = 'auth/reset/confirm';
export const EMBEDGENERATOR = 'embed-generator';
export const INNERSMARTGOALSGENERATOR = 'smart-goals';
export const SMARTGOALSGENERATOR = 'smart-goals-generator';
export const TERMS = 'terms';

export const FAREWELL = 'farewell';
export const MAINTENANCE = 'maintenance';
export const NOTFOUNDURL = 'not-found';

export const SETTINGS = 'settings';

export const DASHBOARD = 'dashboard';
export const RELATIONS = 'relations';
export const SHARED = 'shared';
export const SHAREDNOTE = SHARED + '/relation/';

export const MENTORDATABASE = 'mentors';
export const PROFILE = 'profile';

export const COMPANY = 'company';
export const REPORTS = 'reports';

export const CLIENTPORTAL = 'client-portal';
export const COMMUNITY = 'community';
export const NETWORKING = 'networking';
export const RESOURCES = 'resources';
export const TUTORIAL = 'tutorial';

export const routes: Routes = [
  { path: '', redirectTo: LOGINURL, pathMatch: 'full' },
  {
    path: LOGINURL,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: NOTFOUNDURL,
    component: PageNotFoundComponent,
  },
  {
    path: FAREWELL,
    component: FarewellComponent,
  },
  {
    path: MAINTENANCE,
    component: MaintenanceComponent,
  },
  {
    path: TERMS,
    loadChildren: () => import('./service-pages/platform-terms/platform-terms.module').then(m => m.PlatformTermsModule),
  },
  {
    path: EMBEDGENERATOR,
    loadChildren: () => import('./smart-goals-generator/embed-generator.module').then(m => m.EmbedGeneratorModule),
  },
  {
    path: INNERSMARTGOALSGENERATOR,
    loadChildren: () => import('./smart-goals-generator/inner-smart-goals-generator.module').then(m => m.InnerSmartGoalsGeneratorModule),
    canActivate: [AuthGuardService],
  },
  {
    path: SMARTGOALSGENERATOR,
    loadChildren: () => import('./smart-goals-generator/smart-goals-generator.module').then(m => m.SmartGoalsGeneratorModule),
  },
  {
    path: DASHBOARD,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuardService, RedirectGuard],
  },
  {
    path: RELATIONS,
    loadChildren: () => import('./relation/relation.module').then(m => m.RelationModule),
    canActivate: [AuthGuardService],
  },
  {
    path: PROFILE,
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuardService, MentorOrCoachSectionGuard],
  },
  {
    path: SETTINGS,
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [AuthGuardService],
  },
  {
    path: MENTORDATABASE,
    loadChildren: () => import('./mentor-database/mentor-database.module').then(m => m.MentorDatabaseModule),
    canActivate: [AuthGuardService, MentorSectionGuard],
  },
  {
    path: REPORTS,
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuardService, CompanySectionGuard],
  },
  {
    path: COMPANY,
    loadChildren: () => import('./company-dashboard/company-dashboard.module').then(m => m.CompanyDashboardModule),
    canActivate: [AuthGuardService, CompanySectionGuard],
  },
  {
    path: SHARED,
    loadChildren: () => import('./shared-space/shared-space.module').then(m => m.SharedSpaceModule),
    canActivate: [AuthGuardService],
  },
  {
    path: ':iframeSrc', // a dynamic route parameter
    component: IframeRendererComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes
      // { enableTracing: !environment.production } // <-- for debugging
    ),
  ],
  exports: [RouterModule],
  providers: [AuthGuardService, MentorSectionGuard, MentorOrCoachSectionGuard, CompanySectionGuard, RedirectGuard, CanDeactivateGuard],
})
export class AppRoutingModule {}
